/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
const namespace: string = 'customer';

export default class PersonalAccountPage extends Vue {
	@State('customer') customer: CustomerState | undefined;
	@Action('fetchCount', {namespace}) fetchCount: any;
	@Getter('getAccountMoney', {namespace}) getAccountMoney: any;

	mounted() {
		this.fetchCount();
	}
}
